import { useEffect, useState } from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';

import { common, voyage } from '@microcopies';
import { GuideLine, Map2Line, ShipLine } from '@icons/Map';
import { PriceTag3Line } from '@icons/Finance';
import { InformationBubble, ContentfulImage, Icon, Shimmer } from '@atoms';
import {
  formatPrice,
  isFeatureEnabled,
  mapLocaleToCurrency,
  dateToString,
  utcDateFromString,
  trackEvent
} from '@utils';
import { useLocale, useTranslate } from '@hooks';
import { CloseCircleLine } from '@components/icons/System';
import { defaultLocale } from '@hooks/useLocale';
import useCruiseAvailability from '@hooks/useCruiseAvailability';
import { CalendarLine } from '@components/icons/Business';
import { mapLocaleToCruisesUri } from '@src/utils/mappers/uriMappers';
import ContentfulXRAY from '@components/contentful/ContentfulXray';
import { createSelectVoyageFromListEvent } from '@src/utils/analytics/eventBuilder';
import { returnItemFromCruiseCardInfoModel } from '@src/utils/analytics/convertData';
import { useSiteData } from '@context/DataContext';
import { getBannerStyle } from '@components/molecules/InfoBanner';

export interface IFeatureItem {
  icon: TIconType;
  isBiggerText?: boolean;
  oldValue?: string;
  title?: string;
  prefixText?: string | null | undefined;
  urgentMessage?: string;
  value: string | string[];
  iconBulletClassName?: string;
}

const FeatureItem = ({
  icon,
  isBiggerText,
  prefixText,
  oldValue,
  title,
  urgentMessage,
  value,
  iconBulletClassName
}: IFeatureItem) => (
  <div className="flex items-center flex-1 gap-3" data-testid="feature-item">
    <div
      className={clsx('p-2 rounded-full bg-warm-gray-2', {
        [iconBulletClassName ?? '']: iconBulletClassName
      })}
      data-testid="feature-item-icon"
    >
      <Icon icon={icon} size="1x" />
    </div>

    <div className="flex flex-col">
      {prefixText && <p className="caption text-light-black">{prefixText}</p>}
      <p className="caption text-light-black" data-testid="feature-item-title">
        {title}{' '}
        {oldValue && (
          <span className="line-through" data-testid="feature-item-old-value">
            {oldValue}
          </span>
        )}
      </p>
      <p
        className={clsx(
          'break-words whitespace-normal text-hx-caviar font-medium',
          {
            'body-text-1': isBiggerText,
            'ui-text': !isBiggerText
          }
        )}
        data-testid="feature-item-value"
      >
        {Array.isArray(value) &&
          value.map((val) => <span key={val}>{val}</span>)}
        {!Array.isArray(value) && value}
      </p>
      {urgentMessage && (
        <p
          className="caption text-primary-hrg-red"
          data-testid="feature-item-urgent-message"
        >
          {urgentMessage}
        </p>
      )}
    </div>
  </div>
);

const CruiseCard = ({
  cruise,
  hasMapIcon = false,
  isInfoSectionIndented = false,
  isInsideCarousel = false,
  size = 'normal',
  isInsideDSLayout = false
}: ViewModel.CruiseCard.TCruiseCard) => {
  const [showShipInformationBubble, setShowShipInformationBubble] =
    useState(false);
  const [shipContainerRef, setShipContainerRef] =
    useState<HTMLDivElement | null>(null);
  const [informationBubblePosition, setInformationBubblePosition] = useState<{
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  }>({
    top: '0',
    left: '0',
    right: undefined,
    bottom: undefined
  });

  const { data: siteData } = useSiteData();
  const locale = useLocale();
  const translate = useTranslate(common, (x) => x.common);
  const bannerStyle = getBannerStyle(siteData.infoBanner?.style ?? '');

  const translateVoyageDetails = useTranslate(voyage, (x) => x.voyage.details);
  const {
    location,
    title,
    imageUrl,
    port,
    slug,
    ships,
    destinationNames,
    tags
  } = cruise;

  const uniqueTags = [...new Set(tags)];

  const {
    price,
    strikeThroughPrice,
    departureDate,
    discountPercentage,
    duration,
    isSoldOut,
    isLoading
  } = useCruiseAvailability(cruise.availability, cruise.bookingCodes, locale);

  const mapDepartureDateToLabel = (departure: Date) =>
    dateToString(departure, locale, false, true, true, true, true, true);

  useEffect(() => {
    const scrollHandler = () => {
      if (showShipInformationBubble) {
        setShowShipInformationBubble(false);
      }
    };
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [showShipInformationBubble]);

  const calculateInformationBubblePosition = () => {
    if (!ships || ships.length < 2 || !shipContainerRef) {
      return {};
    }

    const spans = shipContainerRef
      .querySelector('[data-testid="feature-item-value"]')
      ?.getElementsByTagName('span');

    if (!spans) {
      return {};
    }

    const thresholdRight = 150;

    const lastSpan = spans[spans.length - 1];
    const { bottom, left, right } = lastSpan.getBoundingClientRect();
    const windowWidth = document.querySelector('body')?.clientWidth ?? Infinity;

    const shipRect = shipContainerRef.getBoundingClientRect();
    const isCardCloseToRightEdge =
      window.innerWidth - (shipRect.x + shipRect.width) < thresholdRight;

    return {
      top: `${bottom + 15}px`,
      left: isCardCloseToRightEdge ? undefined : `${left - 10}px`,
      right: isCardCloseToRightEdge
        ? `${windowWidth - right - 23}px`
        : undefined
    };
  };

  let departures = [
    ...new Set(
      cruise?.availability?.availabilityData?.voyages.map((v) => v.date)
    )
  ]
    .map((date) => (date ? utcDateFromString(date).getTime() : 0))
    .sort((a, b) => a - b)
    .filter(Boolean)
    .map((dateTime) => new Date(dateTime));

  if ((!departures || departures.length === 0) && departureDate) {
    departures = [new Date(departureDate)];
  }

  const numberOfDepartures = departures?.length ?? 0;

  let departuresText = departures
    .slice(0, 2)
    .map(mapDepartureDateToLabel)
    .join(', ');

  if (!numberOfDepartures || !departures) {
    departuresText = translate((x) => x.unavailable);
  }
  if (numberOfDepartures === 3) {
    departuresText = `${departuresText} + 1 ${translateVoyageDetails(
      (x) => x.moreDeparture
    )}`;
  } else if (numberOfDepartures > 3) {
    departuresText = `${departuresText} + ${
      numberOfDepartures - 2
    } ${translateVoyageDetails((x) => x.moreDepartures)}`;
  }

  const trackCardClick = () => {
    if (!cruise) {
      return;
    }

    trackEvent(
      createSelectVoyageFromListEvent(
        cruise.id,
        cruise.title,
        returnItemFromCruiseCardInfoModel(cruise)
      )
    );
  };

  return (
    <ContentfulXRAY
      title={cruise.title}
      contentType={'Voyage'}
      entryID={cruise.id}
    >
      <>
        <NextLink
          href={`/${mapLocaleToCruisesUri(locale)}/${slug}`}
          locale={locale ?? defaultLocale}
          legacyBehavior
        >
          <a
            data-testid="cruise-card-link"
            className="w-full link-wrapper group"
            data-voyageid-card={cruise.availability?.voyageId}
            aria-label={`${title}. ${departuresText}. ${
              price
                ? `${translate((x) => x.priceFrom)} ${formatPrice(
                    price,
                    mapLocaleToCurrency(locale),
                    locale
                  )}`
                : ''
            }. ${
              uniqueTags.length
                ? `${translate(
                    (x) => x.availablePromotions
                  )}: ${uniqueTags.toString()}`
                : ''
            }`}
          >
            <div
              className={clsx(
                'relative flex flex-col flex-1 overflow-hidden bg-white drop-shadow-sm group-hover:drop-shadow-card-hover group-hover:cursor-pointer',
                {
                  'min-w-[272px]': size === 'small',
                  'min-w-[360px]': size === 'medium',
                  'min-w-[386px]': size === 'normal',
                  'w-[386px]': isInsideCarousel,
                  'w-[377px]': isInsideDSLayout
                }
              )}
              data-testid="cruisecard"
              onClick={trackCardClick}
            >
              <div className={clsx('relative overflow-hidden max-h-max')}>
                <div className="absolute z-10 flex flex-wrap gap-2 top-3 left-3 right-3">
                  <div className="flex flex-wrap items-center gap-2">
                    {uniqueTags[0]?.name && (
                      <span
                        className={clsx(
                          'block p-2 text-2xs rounded-5xl',
                          siteData.infoBanner?.style === 'campaign' &&
                            uniqueTags[0]?.isFeaturedOffer
                            ? bannerStyle.styles
                            : 'text-white bg-hxv-indigo-80'
                        )}
                      >
                        {uniqueTags[0].name}
                      </span>
                    )}
                    {uniqueTags.length > 1 && (
                      <span
                        key="more-offers"
                        className="block p-2 overflow-hidden text-white bg-hxv-indigo-80 text-2xs rounded-5xl whitespace-nowrap text-ellipsis"
                      >
                        + {translate((x) => x.moreOffers)}
                      </span>
                    )}
                  </div>
                </div>
                <ContentfulImage
                  className="cursor-pointer"
                  tabIndex={-1}
                  data-testid="cruisecard-header-image"
                  layout="intrinsic"
                  src={imageUrl ?? undefined}
                  objectFit="cover"
                  alt={title}
                  width={isInsideCarousel ? 386 : 377}
                  height={isInsideCarousel ? 312 : 328}
                />
                {hasMapIcon && (
                  <div className="absolute p-2 bg-white rounded-full bottom-4 left-4">
                    <Icon icon={Map2Line} />
                  </div>
                )}
              </div>

              <div
                className={clsx({
                  'p-6 pt-0 flex flex-col grow-9999': isInfoSectionIndented
                })}
              >
                <div
                  className={clsx('my-6 grow-9999 block', {
                    'opacity-60': isSoldOut
                  })}
                >
                  <div className="flex justify-between w-full mb-4 font-bold text-hx-caviar overline-text">
                    {location && <div>{location}</div>}
                    {duration && (
                      <div className="ml-2">
                        {duration} {translate((x) => x.days)}
                      </div>
                    )}
                  </div>
                  {destinationNames && (
                    <div className="mb-2 font-medium">
                      {destinationNames.filter(Boolean).map((name, i) => (
                        <span key={name} className="overline-text">
                          {i < destinationNames.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </div>
                  )}
                  <h3
                    className="text-lg break-words whitespace-normal h4-text tracking-tightish max-w-fluid text-hx-caviar group-hover:underline"
                    data-testid="cruisecard-heading"
                  >
                    {title}
                  </h3>
                </div>
                <Shimmer isShimmering={isLoading}>
                  <div className="flex flex-col flex-1 gap-5">
                    {!isSoldOut ? (
                      <FeatureItem
                        icon={CalendarLine}
                        title={
                          numberOfDepartures > 1
                            ? translateVoyageDetails((x) => x.departureDates)
                            : translateVoyageDetails((x) => x.departureDate)
                        }
                        value={departuresText}
                      />
                    ) : (
                      <FeatureItem
                        icon={CloseCircleLine}
                        isBiggerText
                        value={translate((x) => x.soldOut).toUpperCase()}
                        iconBulletClassName="text-red-500 bg-red-100"
                      />
                    )}
                    {!isSoldOut && <hr />}
                    <div
                      className={clsx('flex items-start', {
                        'opacity-60': isSoldOut
                      })}
                    >
                      {!isSoldOut && (
                        <FeatureItem
                          isBiggerText={Boolean(price)}
                          icon={PriceTag3Line}
                          title={translate((x) => x.priceFrom)}
                          prefixText={
                            isFeatureEnabled('discountDisplay') &&
                            discountPercentage &&
                            discountPercentage > 0
                              ? translate((x) => x.discountOff, {
                                  discountPercentage: `${discountPercentage}`
                                })
                              : null
                          }
                          value={
                            price
                              ? formatPrice(
                                  price,
                                  mapLocaleToCurrency(locale),
                                  locale
                                )
                              : translate((x) => x.unavailable)
                          }
                          oldValue={
                            strikeThroughPrice &&
                            price &&
                            strikeThroughPrice > price
                              ? formatPrice(
                                  strikeThroughPrice,
                                  mapLocaleToCurrency(locale),
                                  locale
                                )
                              : undefined
                          }
                        />
                      )}
                      {!isSoldOut && Boolean(ships?.length) && (
                        <div
                          ref={(ref) => setShipContainerRef(ref)}
                          className="relative flex-1"
                          onPointerEnter={() => {
                            if (showShipInformationBubble) {
                              return;
                            }
                            setInformationBubblePosition(
                              calculateInformationBubblePosition()
                            );
                            setShowShipInformationBubble(
                              (ships?.length ?? 0) > 1
                            );
                          }}
                          onPointerLeave={() =>
                            setShowShipInformationBubble(false)
                          }
                        >
                          <FeatureItem
                            icon={ShipLine}
                            title={
                              (ships?.length ?? 0) > 1
                                ? translate((x) => x.ships)
                                : translate((x) => x.ship)
                            }
                            value={[
                              ships?.[0] ?? '',
                              (ships?.length ?? 0) > 1 ? ' +1' : ''
                            ]}
                          />
                        </div>
                      )}
                      {port && (
                        <FeatureItem
                          icon={GuideLine}
                          title={translate((x) => x.port)}
                          value={port}
                        />
                      )}
                    </div>
                  </div>
                </Shimmer>
              </div>
            </div>
          </a>
        </NextLink>
        {Boolean(ships?.length) && (
          <InformationBubble
            isVisible={showShipInformationBubble}
            tip={ships ?? []}
            position={informationBubblePosition}
          />
        )}
      </>
    </ContentfulXRAY>
  );
};

export default CruiseCard;
